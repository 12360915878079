/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { sanityFetcher } from '../helpers/sanity'
import { menuPagesQuery } from '../query/menuPages'
import MenuPageLinks from '../components/MenuPageLinks'

const Home = ({ menuPages }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        p: 30,
      }}
    >
      <h1>Odeon TV</h1>
      <h2>
        Downstairs
      </h2>
      <MenuPageLinks menuPages={menuPages} location="downstairs" />
      <h2>
        Upstairs
      </h2>
      <Flex
        sx={{
          flexDirection: 'row',
          gap: '5em',
        }}
      >
        <div>
          <h3>
            Left
          </h3>
          <MenuPageLinks menuPages={menuPages} location="upstairs" orientation="left" />
        </div>
        <div>
          <h3>
            Right
          </h3>
          <MenuPageLinks menuPages={menuPages} location="upstairs" orientation="right" />
        </div>
      </Flex>
    </Flex>
  )
}

export async function getStaticProps() {
  const menuPages = await sanityFetcher(menuPagesQuery);

  return { props: { menuPages } }
}

export default Home
