/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useRef } from 'react'
import { jsx, Text } from 'theme-ui'
import Link from 'next/link'

const MenuPageLinks = ({ menuPages, location, orientation }) => {
  const firstLinkRef = useRef()
  useEffect(
    () => firstLinkRef.current.focus(),
    [],
  )

  if (!menuPages?.length) {
    return (
      [<Text
        variant="noItems"
      >
        No menus configured
      </Text>]
    )
  }

  return menuPages?.map(({ title, slug }, i) => {
    if (!title || !slug) {
      return null;
    }

    const locationHref = orientation ? `${location}/${orientation}` : `${location}`;

    return (
      <Link
        passHref
        key={slug.current}
        href={`/menu/${locationHref}/${slug.current}`}
      >
        <a
          ref={i === 0 ? firstLinkRef : null}
          tabIndex={i + 1}
          sx={{
            textDecoration: 'none',
            ':hover': {
              color: '#fff6cc',
            },
            ':focus': {
              outline: 'none',
              color: '#ffee77',

              ':after': {
                content: '" →"',
                position: 'relative',
              },
            },
          }}
          onClick={() => {if (location === 'upstairs' && !document.fullscreenElement) {
            document.body.requestFullscreen();
          }}}
        >
          <div
            sx={{
              textDecoration: 'underline',
            }}
          >
            {title}
          </div>
        </a>
      </Link>
    );
  });
}

export default MenuPageLinks
